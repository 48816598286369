import React, {useEffect, useState} from 'react';
import {setJobDescriptionMode} from "../../app/reducers/raet/raetReducer";
import {ArrowUturnLeftIcon} from "@heroicons/react/24/outline";
import PWButton from "../../shared/ui/btn/PhonewiseButton";
import {useDispatch, useSelector} from "react-redux";
import {
    setMastogramInstancesData,
    setMastogramMainTableMode,
    setMastogramSelectedGroup
} from "../../app/reducers/mastogram/mastogramReducer";
import {RootState} from "../../app/reducers";
import {behindAPI} from "../../app";
import Alert from '@mui/material/Alert';

const MastogramTelegramCreateEdit = () => {
    const dispatch = useDispatch();
    const selectedInstance = useSelector((state: RootState) => state.mastogram.instanceData);
    const mode = useSelector((state: RootState) => state.mastogram.mainTableMode);
    const [instanceData, setInstanceData] = useState({
        tg_uuid: "",
        tg_name: "",
        tg_enabled: false,
        tg_post: false,
        tg_forward: false,
        tg_auth_code: "",
        prompt_id: null
    });

    useEffect(() => {
        if (mode !== "create") {
            setInstanceData({
                ...instanceData,
                tg_uuid: selectedInstance.tg_uuid || "",
                tg_name: selectedInstance.tg_name || "",
                tg_enabled: selectedInstance.tg_enabled || false,
                tg_post: selectedInstance.tg_post || false,
                tg_auth_code: selectedInstance.tg_auth_code || "",
                tg_forward: selectedInstance.tg_forward || false,
                prompt_id: selectedInstance.prompt_id || null
            });
        } else {
            setInstanceData({
                ...instanceData,
                tg_uuid: "",
                tg_name: "",
                tg_enabled: false,
                tg_auth_code: "",
                tg_post: false,
                tg_forward: false,
                prompt_id: null
            });
        }
    }, [selectedInstance]);

    const updateInstanceData = async (id:string) => {
        const res = await behindAPI.MastogramTelegramUpdate(id, instanceData.tg_enabled, instanceData.tg_post, instanceData.tg_forward, instanceData.prompt_id);
        if (res.success) {
            dispatch(setMastogramMainTableMode('overview'));
            const res = await behindAPI.MastogramTelegramGet();
            dispatch(setMastogramInstancesData(res?.data))
        }
    }

    const onMastogramInstanceSave = async () => {
        if(selectedInstance?.tg_uuid !== '' && selectedInstance?.tg_uuid !== undefined){
            await updateInstanceData(instanceData?.tg_uuid)
        } else {
            const create = await behindAPI.MastogramTelegramCreate();
            if (create.success) {
                await updateInstanceData(create?.data)
            }
        }
    }
    return (
        <div className="max-w-full mx-auto p-4">
            {/*{JSON.stringify(instanceData)}*/}
            <div className='flex items-center justify-between mb-6'>
                <div className='flex items-center'>
                    <div className='bg-gray-100 hover:bg-gray-200 rounded-xl p-2 mr-4 cursor-pointer max-w-9 max-h-9'
                         onClick={() => dispatch(setMastogramMainTableMode('overview'))}
                    >
                        <ArrowUturnLeftIcon style={{width: 20}}/>
                    </div>
                    <div className='text-xl font-semibold'>
                        Edit channel
                    </div>
                </div>
                <div>
                    <PWButton onClick={() => onMastogramInstanceSave()}>
                        Save
                    </PWButton>
                </div>
            </div>
            {mode!=='create' &&
                <div className="mb-4">
                    {instanceData.tg_name =="" &&
                        (
                            <div>
                                <Alert severity="info">Watch the <a href="https://youtu.be/z-uB-s4Jj8g">instruction video</a> first</Alert>
                                <br/>
                                <Alert severity="info">1) Start the bot <a href="https://t.me/mastogrambot">@mastogram_bot</a> <br/> 2) Add <a href="https://t.me/mastogrambot">@mastogram_bot</a> to your Telegram channel as an admin <br/> 3) Send the Auth code to the channel as a text message</Alert>
                            </div>
                        )
                    }

                    <label htmlFor="token" className="block font-medium text-gray-700">
                        Auth code
                    </label>
                    <input
                        type="text"
                        id="token"
                        disabled={true}
                        value={instanceData.tg_auth_code}
                        onChange={(e) => setInstanceData({ ...instanceData, tg_auth_code: e.target.value })}
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md bg-gray-200"
                    />
                </div>
            }
            <div className="flex items-center mb-4">
                <label
                    htmlFor="instanceEnabled"
                    className="mr-4 font-medium text-gray-700"
                >
                    Instance enabled
                </label>
                <label className="inline-flex items-center cursor-pointer">
                    <input
                        type="checkbox"
                        checked={instanceData.tg_enabled}
                        onChange={() => setInstanceData({
                            ...instanceData,
                            tg_enabled: !instanceData.tg_enabled // Toggle tg_enabled on change
                        })}
                        className="sr-only peer"
                    />
                    <div
                        className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-violet-300 dark:peer-focus:ring-violet-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-violet-600"
                    ></div>
                </label>
            </div>

            <div className="mb-4">
                <h3 className="font-semibold text-gray-700">Bridge options</h3>
                <div className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={instanceData.tg_post}
                            onChange={() => setInstanceData({
                                ...instanceData,
                                tg_post: !instanceData.tg_post
                            })}
                            className="form-checkbox h-5 w-5 text-violet-600 bg-violet-500"
                        />
                        <span className="ml-2 text-gray-700">(Write) Allow Posting to This Channel</span>
                    </label>
                </div>
                <div className="mt-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            checked={instanceData.tg_forward}
                            onChange={() => setInstanceData({
                                ...instanceData,
                                tg_forward: !instanceData.tg_forward
                            })}
                            className="form-checkbox h-5 w-5 text-violet-600"
                        />
                        <span className="ml-2 text-gray-700">(Read) Auto-Forward from This Channel</span>
                    </label>
                </div>
            </div>
            {mode!=='create' &&
                <div className={'text-black/40 text-sm'}>
                    <i>Instance ID: {instanceData.tg_uuid}</i>
                </div>
            }

        </div>
    );
};

export default MastogramTelegramCreateEdit;